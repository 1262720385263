<template>
  <div v-if="item">
    <div v-if="viewList" class="topic-video-item-list">
      <div class="ratio-custom">
        <div>
          <div class="backdrop-gradient d-flex d-lg-none top-0 left-0 w-100 position-absolute align-items-center justify-content-end" v-if="!updating">
            <span :title="item.is_followed ? 'Bỏ theo dõi' : 'Theo dõi'" :class="{'active': item.is_followed}" class="pointer follow-mobile" @click.prevent="followEvent" v-html="require('~/assets/mlearn/icons/video/follow.svg?raw')"></span>
          </div>
          <img class="thumbnail pointer" :src="item.avatar ? item.avatar: item.thumbnail" alt="thumbnail" @click="redirectTopic"/>
        </div>
      </div>
      <div class="content">
        <div class="video-name pointer display-html display-2-line" :class="{'viewer': item.is_viewed}" @click="redirectTopic">{{ item.name }}</div>
        <div class="video-info"><span>{{ item.class_name }}</span> &#8226; <span>{{ item.category_name }}</span></div>
        <div class="d-flex justify-content-between align-items-end">
          <div class="number-video">{{ item.number_video }} Video</div>
          <div class="follow-video d-none d-lg-block" v-if="!updating">
            <span :title="item.is_followed ? 'Bỏ theo dõi' : 'Theo dõi'" :class="{'active': item.is_followed}" class="pointer" @click="followEvent" v-html="require('~/assets/mlearn/icons/video/follow.svg?raw')"></span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="topic-video-item-detail">
      <div class="form-data">
        <div class="thumb ratio-custom">
          <div>
            <img class="thumbnail pointer" :src="item.avatar" alt="thumbnail" @click="redirectTopic"/>
          </div>
        </div>
        <div class="content ml-3">
          <div class="video-name pointer display-html display-1-line" :class="{'viewer': item.is_viewed}" @click="redirectTopic">{{ item.name }}</div>
          <div class="number-video">{{ item.number_video }} Video</div>
          <div class="video-info"><span>{{ item.class_name }}</span> &#8226; <span>{{ item.category_name }}</span></div>
        </div>
        <div v-if="!updating" class="follow-action align-self-end align-self-lg-center text-center" :class="{'active': item.is_followed}" @click="followEvent">
          <div :title="item.is_followed ? 'Bỏ theo dõi' : 'Theo dõi'" class="pointer" v-html="require('~/assets/mlearn/icons/video/follow.svg?raw')"></div>
          <div class="d-none d-lg-block pt-1 pointer">{{ item.is_followed ? 'Bỏ theo dõi' : 'Theo dõi' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'

const followResource = new Resource('library/topic/follow')
const removeFollowResource = new Resource('library/topic/unFollow')
export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    deleteAction: {
      type: Boolean,
      default () {
        return false
      }
    },
    updating: {
      type: Boolean,
      default () {
        return false
      }
    },
    viewList: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      processing: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    followEvent () {
      if (this.item.is_followed) {
        this.removeFollow()
      } else {
        this.saveFollow()
      }
    },
    saveFollow () {
      if (!this.user) {
        this.$bvModal.show('modal-login')
        return
      }
      if (this.processing) {
        return
      }
      const data = {
        topic_id: this.item.id
      }
      followResource.store(data)
        .then(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.item.is_followed = 1
          let notify
          if (this.deleteAction) {
            notify = this.item
          } else {
            notify = `Đã theo dõi Khóa học "<b>${this.item.name}</b>`
          }
          this.$emit('showNotify', notify)
        })
        .catch((err) => {
          this.$notify({
            type: 'warning',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    removeFollow () {
      if (!this.user) {
        return
      }
      if (this.processing) {
        return
      }
      const data = {
        topic_id: this.item.id
      }
      removeFollowResource.store(data)
        .then(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.item.is_followed = 0
          let notify
          if (this.deleteAction) {
            notify = this.item
          } else {
            notify = `Đã bỏ theo dõi Khóa học "<b>${this.item.name}</b>`
          }
          this.$emit('showNotify', notify)
        })
        .catch((err) => {
          this.$notify({
            type: 'warning',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    redirectTopic () {
      if (this.updating) {
        return
      }
      this.$router.push('/khoa-hoc/' + this.item.slug)
    }
  }
}
</script>

<style lang="scss" scoped>
.topic-video-item-list {
  background: var(--white);
  border: 1px solid #CED4DA;
  border-radius: 8px;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

    .video-name {
      color: var(--primary) !important;
    }
  }

  .ratio-custom {
    --aspect-ratio: 59.60%;

    .thumbnail {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .backdrop-gradient {
      height: 32px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      border-radius: 8px 8px 0px 0px;

      .follow-mobile {
        margin-right: 10px;
      }
    }
  }

  .content {
    padding: 10px 16px 16px;
    @media (max-width: 576px) {
      padding: 10px;
    }

    .video-name {
      font-weight: 600;
      font-size: 14px;
      min-height: 42px;

      &.viewer {
        color: #75777B;
      }
    }

    .video-info {
      margin-bottom: 8px;
      color: var(--primary);
      font-size: 14px;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    .number-video {
      font-size: 13px;
      color: #65676B;
    }
  }
}

.topic-video-item-detail {
  .form-data {
    .ratio-custom {
      --aspect-ratio: 60%;
      width: 166px;
      @media (max-width: 576px) {
        width: 110px;
      }

      img {
        border-radius: 4px;
      }
    }

    .content {
      .video-name {
        font-weight: 600;
        font-size: 16px;
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }

      .video-info {
        color: var(--primary);
        font-size: 14px;
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }

      .number-video {
        color: #868789;
        font-size: 14px;
        margin-bottom: 8px;
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }

    .follow-action {
      color: #828282;
      font-size: 14px;
      min-width: 100px;

      &.active {
        color: var(--primary);
      }

      @media (max-width: 576px) {
        font-size: 12px;
        min-width: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.topic-video-item-list {
  .ratio-custom {
    .backdrop-gradient {
      .follow-mobile {
        svg {
          path {
            fill: var(--white);
          }
        }
      }
    }
  }
}
</style>
